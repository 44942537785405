import {Typography, TypographyProps, useTheme} from "@mui/material";

export function TertiaryTitle(props: TypographyProps) {
    const theme = useTheme()
    return (
        <Typography
            variant={'h3'} {...props}
            sx={{
                fontFamily: 'pragmatica-extended,sans-serif',
                textTransform: 'uppercase',
                fontWeight: 300,
                // marginTop: 50,
                fontSize: 26,
                position: 'relative',
                zIndex: 35,
                color: "#fdfdfd",
                ...props.sx
            }}
        />
    )
}
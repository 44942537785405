import {Box, Container, Grid, Typography, useTheme} from "@mui/material";
import CornerLogo from "../assets/corner-logo.png";
import KrakenBG from "../assets/kraken.jpg";
import BannerBot from "../assets/banner-bot-deco.png";
import LinesArea from "../assets/features-deco.svg";
import React from "react";
import {SecondaryTitle} from "../components/typography/SecondaryTitle";
import {PrimaryTitle} from "../components/typography/PrimaryTitle";
import {LargeCard} from "../components/section/LargeCard";
import {SecondaryButton} from "../components/button/SecondaryButton";
import {BigButtonGroup} from "../components/button/BigButtonGroup";
import {large_cards, small_cards} from "../data/cards";
import BGSection from "../assets/BGSection.jpg"
import BGSerialLines from "../assets/bg_lines_serial_number.png"
import Footer from "../views/footer/Footer";
import {TertiaryTitle} from "../components/typography/TertiaryTitle";
import {SmallCard} from "../components/section/SmallCard";

export function Home() {
    const theme = useTheme()

    return (
        <Grid container spacing={0} sx={{
            backgroundColor: '#1e1e1e',
        }}>
            <Grid item xs={12} sx={{
                position: 'relative',
                maxHeight: 800,
                overflow: 'hidden',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    background: 'white',
                    height: '1.25px',
                    [theme.breakpoints.up('md')]: {
                        width: 'calc(100% - 360px)',
                        left: '360px',
                        top: '9px',
                    },
                    [theme.breakpoints.down('md')]: {
                        width: 'calc(100% - 180px)',
                        left: '180px',
                        top: '4.5px',
                        height: '1px',
                    },
                    // top: 'calc(-1125px + 9px)',
                    zIndex: 1
                }
            }}>
                <Box sx={{
                    position: 'absolute', zIndex: 1,
                    [theme.breakpoints.up('md')]: {
                        width: '360px',
                        height: '380px'
                    },
                    [theme.breakpoints.down('md')]: {
                        width: '180px',
                        height: '190px'
                    }
                }}>
                    <img src={CornerLogo} style={{
                        position: 'absolute', zIndex: 1,
                        maxHeight: '100%',
                        maxWidth: '100%',
                    }} alt={'corner decoration'}/>
                </Box>
                {/*<img src={DrakeLogo} style={{*/}
                {/*    position: 'absolute',*/}
                {/*    zIndex: 33,*/}
                {/*    maxWidth: 144,*/}
                {/*    height: 'auto',*/}
                {/*    left: 20,*/}
                {/*    top: 48*/}
                {/*}}/>*/}
                <Box sx={{
                    '& > img': {
                        width: '100%', objectFit: 'contain',
                        [theme.breakpoints.down('md')]: {
                            objectFit: 'cover',
                            height: 500,
                        },
                    }
                }}>
                    <img src={KrakenBG} alt={'Drake Kraken Header'}/>
                </Box>

                <img src={BannerBot}
                     style={{left: 0, width: '100%', bottom: 0, height: 64, position: 'absolute', zIndex: 1}}
                     alt={'Banner Decoration Bottom'}
                />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    // height: 2000,
                    marginTop: -1.5,
                    position: 'relative',
                    zIndex: 3,
                    marginBottom: 15,
                    backgroundColor: '#1e1e1e',
                    '&::before': {
                        background: `url(${LinesArea}) no-repeat center -375px`,
                        content: '""',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        top: -12,
                        left: 0,
                        zIndex: 4
                    }
                }}>
                    <Container maxWidth={'xl'}>
                        <PrimaryTitle>
                            Dutch East India Company
                        </PrimaryTitle>
                        <SecondaryTitle>
                            Settlement. Trade. Extraction.
                        </SecondaryTitle>
                        <Typography variant={'h6'} sx={{
                            fontFamily: 'pragmatica-extended,sans-serif',
                            position: 'relative',
                            zIndex: 4,
                            marginTop: 20,
                            color: '#a3a3a3',
                            maxWidth: 800
                        }}>
                            We aim to offer a casual social gaming experience as Star Citizen grows into the game it
                            will one day be. Our main focus is commerce, between trade logistics, contracted
                            construction, remote support, and escort services, offering a unique interface to facilitate
                            the player driven economy, and offer insured transport of your goods.
                        </Typography>

                        <Box sx={{
                            "&>:first-child": {
                                // borderTopLeftRadius: 64,
                                borderTopRightRadius: 64,
                            },
                            "&>:last-child": {
                                borderBottomLeftRadius: 64,
                                // borderBottomRightRadius: 64,
                            },
                        }}>
                            {
                                large_cards.map(
                                    card => <LargeCard {...card}/>
                                )
                            }
                        </Box>

                    </Container>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{marginBottom: 20}}>
                    <Container maxWidth={'xl'}>
                        <SecondaryTitle sx={{fontSize: 50,}}>
                            Join Us
                        </SecondaryTitle>
                        <PrimaryTitle sx={{fontSize: 92,}}>
                            Today
                        </PrimaryTitle>
                        <Typography color={'text.secondary'}
                                    sx={{maxWidth: 800, fontWeight: '600', marginBottom: 4}}>
                            We welcome players new and old who are looking to work with us to earn money and
                            meet new
                            people. All people are welcome, no matter your level of commitment. Greater
                            involvement will
                            always be rewarded. Apply on Spectrum and join
                            our Discord.

                            {/*Or, if you want to work with us as an affiliate, learn more about contracting for us below.*/}
                        </Typography>

                        <BigButtonGroup>
                            <SecondaryButton to={'https://discord.gg/YATfrNv5J5'}>Discord</SecondaryButton>
                            <SecondaryButton bottom
                                             to={'https://robertsspaceindustries.com/orgs/DEICOMPANY'}>Spectrum</SecondaryButton>
                        </BigButtonGroup>
                    </Container>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{background: '#000', minHeight: 500}}>
                <Container maxWidth={'md'} sx={{marginTop: 15, marginBottom: 15}}>
                    <TertiaryTitle>
                        Our History
                    </TertiaryTitle>
                    <br/>
                    <Typography color={'text.secondary'}>
                        <p>
                            Founded on the 20th of March 1602, the Dutch East India Company (Vereenigde Oostindische
                            Compagnie, or VOC) has a long and complex history. We have taken many names, and have many
                            chapters to our history. We were originally consolidated by the Dutch government from a
                            number
                            of Dutch trading companies. The VOC specialized in the spice trade between Europe and Asia,
                            and
                            established a number of the first trading colonies in the Asian Pacific. After 200 years of
                            successful operation, the VOC was nationalized by the Dutch government.
                        </p>

                        <p>
                            Our nationalization and incorporation was not the end of the VOC, however. Leading up to the
                            founding of the UEE in 2546, with the looming changes of the international government, the
                            Kingdom of the Netherlands was privatized, reforming the Dutch East India Company with King
                            William IX as its CEO. We have since operated as a private company, retaining the prestige
                            and
                            grandeur of the former monarchy, with the position of CEO symbolically passed down following
                            the
                            laws of primogeniture as established in the VOC’s original charter.
                        </p>

                        <p>
                            Today we are lead by Henry I, who assumed the position of CEO in 2949 after the abdication
                            of
                            his father. Previously, growth within the VOC has been stagnant, and we look to Henry to
                            return
                            the VOC to its former glory.
                        </p>
                    </Typography>
                </Container>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    // height: 2000,
                    position: 'relative',
                    zIndex: 3,
                    [theme.breakpoints.up('md')]: {
                        marginBottom: 25,
                    },
                    [theme.breakpoints.down('md')]: {
                        marginBottom: 5,
                    },
                    minHeight: 800,
                    backgroundColor: '#1e1e1e',
                    '&::before': {
                        background: `url(${BGSerialLines}) no-repeat 50%`,
                        backgroundSize: 'cover',
                        content: '""',
                        position: 'absolute',
                        minHeight: 1000,
                        width: '100%',
                        top: 0,
                        right: 0,
                        zIndex: 5,
                    }
                }}>
                    <Container maxWidth={'xl'}>
                        <Box height={50}/>
                        <PrimaryTitle sx={{fontSize: 92}}>
                            Our Services
                        </PrimaryTitle>
                        <SecondaryTitle sx={{fontSize: 50,}}>
                            What we Do
                        </SecondaryTitle>

                        <Grid container spacing={4} justifyContent={'center'} alignItems={'center'}>
                            {
                                small_cards.map(
                                    card => <SmallCard {...card}/>
                                )
                            }
                        </Grid>
                    </Container>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{
                minHeight: 600, overflow: 'hidden', marginBottom: 15,
                background: `url(${BGSection})`,
                backgroundSize: 'cover',
            }}/>
            <Grid item xs={12}>
                <Box sx={{marginBottom: 20}}>
                    <Container maxWidth={'xl'}>
                        <PrimaryTitle sx={{fontSize: 92,}}>
                            Contract for Us
                        </PrimaryTitle>
                        <SecondaryTitle sx={{fontSize: 50,}}>
                            We are always looking for contractors!
                        </SecondaryTitle>
                        <br/>
                        <TertiaryTitle>
                            What is a Contractor?
                        </TertiaryTitle>
                        <br/>
                        <Typography color={'text.secondary'}
                                    sx={{maxWidth: 800, fontWeight: '600', marginBottom: 4}}>

                            A contractor is an independent person or business that we work with to fulfill our
                            contracts. We accept contracts from our clients, and when we need help fulfilling a
                            contract, we will reach out to our contractors. Our contractors help us with our
                            deliveries, construction, and various support services, with access to higher trust
                            contracts the more they do for us. Contractors are in high demand. The Dutch East
                            India
                            Company would be nothing without its contractors.
                        </Typography>
                        <SecondaryButton to={"https://sc-market.space/"}>Apply</SecondaryButton>
                    </Container>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{backgroundColor: 'black', paddingTop: 10}}>
                <Container maxWidth={'lg'}>
                    <Footer/>
                </Container>
            </Grid>
        </Grid>
    )
}
import React from "react";
import {Avatar, Box, Divider, Grid, Typography} from "@mui/material";
import CommunityDisclaimer from "../../assets/MadeByTheCommunity_White.png";

export default function Footer(): JSX.Element {
    // TODO: Make all app external links use plain `a` tags
    // TODO: Make all external links into constants in constants.ts
    return (
        <Grid container spacing={2} justifyContent={'center'} sx={{marginBottom: 5}}>
            <Grid item xs={8}>
                <Typography variant="body2" color="textSecondary" align="center" sx={{
                    fontFamily: 'spacefont,sans-serif',
                    textTransform: 'uppercase',
                }}>
                    {'Copyright © '}
                    Dutch East India Company
                    {' '}
                    {new Date().getFullYear()}
                    {'.'}
                {/*    <Divider/>*/}
                {/*    {'Star Citizen®, Squadron 42®, Roberts Space Industries®, and Cloud Imperium® are registered trademarks of Cloud Imperium Rights LLC'}*/}
                {/*    <Divider/>*/}
                {/*    This is an unofficial Star Citizen fansite, not affiliated with the Cloud Imperium group of*/}
                {/*    companies. All content on this site not authored by its host or users are property of their*/}
                {/*    respective owners*/}
                </Typography>
            </Grid>
            {/*<Grid item xs={2}>*/}
            {/*    <Box*/}
            {/*        display="flex"*/}
            {/*        alignItems="center"*/}
            {/*        justifyContent="center"*/}
            {/*    >*/}
            {/*        <Avatar src={CommunityDisclaimer} sx={{*/}
            {/*            width: 96,*/}
            {/*            height: 96*/}
            {/*        }}/>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}
        </Grid>

    );
}
import React from 'react';
import './App.css';
import {Box, CssBaseline, ThemeProvider} from "@mui/material";
import {theme} from "./styles/theme";
import {Home} from "./pages/Home";
import {HashRouter, Routes} from "react-router-dom";
import {Route} from "react-router";
import {TopNav} from "./views/nav/TopNav";
import Footer from "./views/footer/Footer";


function App() {
    return (<HashRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <TopNav/>
                <Box sx={{height: 64, backgroundColor: '#2e2e2e'}}/>
                <Routes>
                    <Route path={'/'} element={<Home/>}/>
                </Routes>
            </ThemeProvider>
        </HashRouter>
    );
}

export default App;

import {Box} from "@mui/material";

export function BigButtonGroup(props: {children: React.ReactNode}) {
    return (
        <Box sx={{
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 12,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            width: 600,
            maxWidth: '100%',
            '& > *': {
                width: '100%',
                marginBottom: .5,
            },
            '& > :last-child': {
                width: '100%',
                marginBottom: 0,
            },
        }}>
            {props.children}
        </Box>
    )
}
import {Box, Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import React from "react";
import {TertiaryTitle} from "../typography/TertiaryTitle";
import CardCorner from "../../assets/card-corner-serial.png"

export function SmallCard(props: { image: string, title: React.ReactNode | string, body: React.ReactNode | string }) {
    return (
        <Grid item xs={12} md={4} sx={{
            display: 'relative',
            overflow: 'hidden',
            zIndex: 6,
            maxWidth: '426px',
        }}>
            <Card sx={{
                overflow: 'hidden',
                height: 475,
                objectFit: 'contain',
                borderWidth: 2,
                borderColor: '#575757',
                borderStyle: 'solid',
                borderRadius: 3,
                marginTop: 5,
                boxSizing: 'border-box',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 24px',
                backgroundColor: '#1e1e1e',
                maxWidth: '426px',
            }}>
                <CardMedia sx={{width: '100%', height: 240}}>
                    <img src={props.image} style={{
                        height: '100%',
                    }}/>
                </CardMedia>

                <CardContent sx={{padding: 3}}>
                    <TertiaryTitle sx={{marginBottom: 3}}>
                        {props.title}
                    </TertiaryTitle>
                    <Typography color={'text.secondary'} sx={{
                        fontWeight: '300',
                        fontSize: 15,
                        letterSpacing: .5,
                        lineHeight: '22px',
                        fontFamily: 'pragmatica-extended,sans-serif',
                    }}>
                        {props.body}
                    </Typography>
                </CardContent>
            </Card>
            <Box sx={{
                borderBottomLeftRadius: 3,
                // borderBottomRightRadius: '50%',
                borderLeftWidth: 2,
                borderBottomWidth: 2,
                borderRightWidth: 2,
                borderTopWidth: 0,
                borderColor: '#575757',
                borderStyle: 'solid',
                bottom: 25,
                position: 'relative',
                height: 60,
                zIndex: 7,
                width: `calc(360)`,
                maxWidth: '426px',
                background: '#1e1e1e',
                '&::after': {
                    background: `url(${CardCorner}) no-repeat center`,
                    content: '""',
                    position: 'absolute',
                    bottom: -13,
                    right: -10,
                    width: 62,
                    height: 71,
                    backgroundSize: 'contain',
                    zIndex: 50
                },
            }}/>
        </Grid>
    )
}
import {Typography, TypographyProps, useTheme} from "@mui/material";

export function PrimaryTitle(props: TypographyProps) {
    const theme = useTheme()

    return (
        <Typography
            variant={'h1'} {...props}
            sx={{
                fontFamily: 'axia-stencil,sans-serif',
                textTransform: 'uppercase',
                color: '#fdfdfd',
                fontWeight: 900,
                // marginTop: 50,
                fontSize: 96,
                [theme.breakpoints.down('md')]: {
                    fontSize: 72,
                },
                position: 'relative',
                zIndex: 35,
                ...props.sx
            }}
        />
    )
}
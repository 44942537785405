import {Box, Grid, Typography, useTheme} from "@mui/material";
import GreyLine from "../../assets/grey-line.png";
import React from "react";

export function LargeCard(props: { image: string, title: React.ReactNode | string, body: React.ReactNode | string }) {
    const theme = useTheme()

    return (
        <Box sx={{
            overflow: 'hidden',
            maxHeight: 500,
            position: 'relative',
            objectFit: 'contain',
            borderWidth: 1,
            borderColor: '#999',
            borderStyle: 'solid',
            borderRadius: 3,
            marginTop: 7,
            display: 'flex',
            boxSizing: 'border-box',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 24px',
            // borderTop: 'none',
            zIndex: 40,
            // '&::before': {
            //     content: '""',
            //     width: '100%',
            //     height: 0,
            //     position: 'absolute',
            //     top: 0,
            //     left: 0,
            //     borderLeft: `29px solid red`,
            //     borderRight: '29px solid red',
            //     borderBottom: `29px solid url(${KrakenBGLarge})`,
            //     borderTop: '1px solid #999',
            //     // zIndex: 41,
            // }
        }}>
            <Grid container>
                <Grid item xs={8} sx={{
                    overflow: 'hidden',
                    [theme.breakpoints.down('md')]: {
                        display: 'none',
                    },
                }}>
                    <img src={props.image} style={{
                        maxWidth: `calc(100% - 495)`,
                        height: 500,
                        objectFit: 'cover',
                        // width: '100%',
                    }}/>
                </Grid>
                <Grid item xs={12} md={4} sx={{
                    height: '100%',
                    [theme.breakpoints.down('md')]: {
                        background: `url(${props.image})`,
                        backgroundSize: 'cover',
                    },
                }}>
                    <Box sx={{
                        [theme.breakpoints.up('md')]: {
                            maxWidth: 495,
                            background: '#0f0f0f',
                        },
                        [theme.breakpoints.down('md')]: {
                            background: '#0f0f0f80',
                        },
                        display: 'flex',
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 2,
                        paddingRight: 4,
                        height: '100%'
                    }}>
                        <Box sx={{marginRight: 2}}>
                            <Typography variant={'h3'} sx={{
                                textOrientation: 'mixed',
                                writingMode: 'vertical-rl',
                                textTransform: 'uppercase',
                                color: 'white',
                                fontWeight: 800,
                                transform: 'rotate(180deg)',
                                fontSize: 40,
                                fontFamily: 'pragmatica-extended,sans-serif',
                            }}>
                                {props.title}
                            </Typography>
                        </Box>
                        <Box>
                            <img src={GreyLine} style={{height: '100%', width: 2}}/>
                        </Box>
                        <Typography color={'text.secondary'} sx={{
                            marginLeft: 3,
                            fontWeight: '300',
                            fontSize: 15,
                            letterSpacing: .5,
                            lineHeight: '22px',
                            fontFamily: 'pragmatica-extended,sans-serif',
                            [theme.breakpoints.down('md')]: {
                                color: 'white',
                                fontSize: 20,
                                lineHeight: '32px',
                            },
                        }}>
                            {props.body}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            {/*<img src={ExploreBGDecoLeft} style={{display: 'absolute', width: 4, zIndex: 35}}/>*/}

        </Box>
    )
}
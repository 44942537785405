import {Typography, TypographyProps, useTheme} from "@mui/material";

export function SecondaryTitle(props: TypographyProps) {
    const theme = useTheme()

    return (
        <Typography
            variant={'h3'} color={'secondary'} {...props}
            sx={{
                fontFamily: 'axia-stencil-slim,sans-serif',
                textTransform: 'uppercase',
                fontWeight: 300,
                // marginTop: 50,
                fontSize: 48,
                [theme.breakpoints.down('md')]: {
                    fontSize: 36,
                },
                position: 'relative',
                zIndex: 35,
                letterSpacing: 8,
                ...props.sx
            }}
        />
    )
}
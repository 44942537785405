import {AppBar, Box, Button, Container, Grid, IconButton, Theme, Toolbar, useTheme} from "@mui/material";
import {ReactComponent as StarCitizenLogoComponent} from "../../assets/StarCitizen2.svg";
import {ReactComponent as FleetyardsComponent} from "../../assets/fleetyards-round.svg";
import {Discord} from "mdi-material-ui";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import React from "react";

function delay(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export const refs: Record<string, React.RefObject<any>> = {
    // "#projects": React.createRef<HTMLDivElement>(),
    // "#experience": React.createRef<HTMLDivElement>(),
    // "#contact": React.createRef<HTMLDivElement>(),
    // "#about": React.createRef<HTMLDivElement>(),
}

function NavItemRef(props: { to: string, children?: React.ReactNode }): JSX.Element {
    const theme: Theme = useTheme()
    const location = useLocation();

    return (
        <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
                padding: theme.spacing(2),
                width: theme.spacing(24),
                // borderRadius: 4,
                // '&:hover': {
                //     backgroundColor: "#00000022",
                // },
                // '&:active': {
                //     backgroundColor: "#00000022",
                // },
            }}
        >
            <Link to={"/"} style={{textDecoration: 'none', color: 'inherit'}}>
                <Button
                    onClick={async () => {
                        if (location.pathname !== "/") {
                            await delay(200)
                        }
                        const ref = refs[props.to]
                        if (ref && ref.current) {
                            ref.current.scrollIntoView({behavior: 'smooth', block: 'start'})
                        }
                    }}
                    color={'secondary'}
                >
                    {/*<Typography variant="h6" color={"primary"} align={"center"}>*/}
                    {props.children}
                    {/*</Typography>*/}
                </Button>
            </Link>
        </Grid>

    )
}

function NavItem(props: { to: string, children?: React.ReactNode }): JSX.Element {
    const theme: Theme = useTheme()
    return (
        <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
                padding: theme.spacing(2),
                width: theme.spacing(24),
                // borderRadius: 4,
                // '&:hover': {
                //     backgroundColor: "#00000022",
                // },
                // '&:active': {
                //     backgroundColor: "#00000022",
                // },
            }}
        >
            <a href={props.to} style={{textDecoration: 'none', color: 'inherit'}}>
                <Button color={'secondary'} sx={{
                    fontFamily: 'axia-stencil-slim,sans-serif',
                    textTransform: 'uppercase',
                    fontSize: 20,
                    paddingTop: 0,
                    paddingBottom: 0,
                }}>
                    {props.children}
                </Button>
            </a>
        </Grid>

    )
}

export function TopNav() {
    const theme = useTheme()

    return (
        <AppBar
            elevation={0}
            position="absolute"
            sx={{
                zIndex: theme.zIndex.drawer + 1,
                width: `100%`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                backdropFilter: 'blur(8px)',
                background: 'rgb(30,30,30)',
                borderBottom: '1px solid rgba(140,43,39,0.5)',
                borderTop: '1px solid rgba(140,43,39,0.5)',
            }}
        >
            <Toolbar
                // className={classes.toolbar}
                sx={{
                    [theme.breakpoints.up('md')]: {
                        paddingRight: 24, // keep right padding when drawer closed
                    },
                    [theme.breakpoints.down('md')]: {
                        paddingRight: 2, // keep right padding when drawer closed
                        justifyContent: 'space-between'
                    },
                    height: theme.spacing(8),
                    // background: 'linear-gradient(45deg, #d31044 30%, #60071f 50%)', //'rgb(255,255,255,.4)',
                    // boxShadow: `0 3px 5px 3px #60071f`,
                    overflow: 'visible',
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <NavItem to={"https://sc-market.space"}>
                            Contracting
                        </NavItem>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <IconButton component={'a'} href={'https://discord.gg/YATfrNv5J5'} sx={{
                                marginTop: 4,
                                marginBottom: 4,
                            }}>
                                <Discord style={{
                                    height: 32,
                                    width: 32,
                                    color: '#cf333e'
                                }}/>
                            </IconButton>
                            <IconButton component={'a'} href={'https://robertsspaceindustries.com/orgs/DEICOMPANY'}
                                        sx={{
                                            marginTop: 4,
                                            marginBottom: 4,
                                        }}>
                                <StarCitizenLogoComponent style={{
                                    filter: 'invert(27%) sepia(66%) saturate(2864%) hue-rotate(339deg) brightness(86%) contrast(89%)',
                                    height: 32,
                                    width: 32,
                                }}/>
                            </IconButton>
                            <IconButton component={'a'} href={'https://fleetyards.net/fleets/voc-fleet/ships/'} sx={{
                                marginTop: 4,
                                marginBottom: 4,
                            }}>
                                <FleetyardsComponent style={{
                                    'filter': 'invert(27%) sepia(66%) saturate(2864%) hue-rotate(339deg) brightness(86%) contrast(89%)',
                                    height: 32,
                                    width: 32,
                                }}/>
                            </IconButton>
                        </Box>
                    </Box>
            </Toolbar>
        </AppBar>
    )
}
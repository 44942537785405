import {Box, ButtonBase, useTheme} from "@mui/material";
import {TertiaryTitle} from "../typography/TertiaryTitle";
import React from "react";

export function SecondaryButton(props: { children: React.ReactNode, bottom?: boolean, to: string }) {
    const theme = useTheme()

    return (
        <a href={props.to} style={{color: 'inherit', textDecoration: 'none'}}>
            <ButtonBase sx={{
                width: '100%',
            }}>
                <Box sx={{
                    backgroundColor: theme.palette.secondary.main,
                    padding: 3,
                    width: '100%',
                }}>
                    <TertiaryTitle sx={{
                        textAlign: 'left',
                    }}>{props.children}</TertiaryTitle>
                </Box>
                <Box>
                    {
                        props.bottom ?
                            <>
                                <Box sx={{
                                    height: 39,
                                    width: 39,
                                    // maxWidth: '75%',
                                    backgroundColor: theme.palette.secondary.main,
                                }}/>
                                <Box sx={{
                                    minHeight: 0,
                                    width: 0,
                                    borderTop: `39px solid ${theme.palette.secondary.main}`,
                                    borderRight: '39px solid transparent',
                                }}/>
                            </>
                            :
                            <>
                                <Box sx={{
                                    height: 0,
                                    width: 0,
                                    borderBottom: `39px solid ${theme.palette.secondary.main}`,
                                    borderRight: '39px solid transparent',
                                }}/>
                                <Box sx={{
                                    height: 39,
                                    width: 39,
                                    // maxWidth: '75%',
                                    backgroundColor: theme.palette.secondary.main,
                                }}/>
                            </>
                    }
                </Box>
            </ButtonBase>
        </a>
    )
}
import Caterpillar from "../assets/C2_Gallery_Landing.jpg"
import Pioneer from "../assets/Pioneer.jpg"
import Orion from "../assets/6cd6bec50e9eebe449929cb4e0b61cd4_XL.jpg"
import TradeCenter from "../assets/trade-center.jpg"
import Covalex from "../assets/Covalex.webp"
import Fleet from "../assets/Fleet.jpg"

export const large_cards = [
    {
        title: 'Acquisition',
        image: Orion,
        body: 'Need a certain resource for crafting or construction? We can find it, be it rare, sensitive to quantum travel, or needed in bulk.'
    },
    {
        title: 'Construction',
        image: Pioneer,
        body: "Looking to settle but don't have a CNOU Pioneer at the ready? We'll take care of the heavy lifting. We can even source the materials you need to build with our Resource Acquisition services."
    },
    {
        title: 'Logistics',
        image: Caterpillar,
        body: "Looking to get your resources securely from one end of the 'verse to the other? Send it with us and we will get it delivered as soon as possible. Now offering insured deliveries!"
    },
]

export const small_cards = [
    {
        title: 'Resources',
        image: TradeCenter,
        body: 'We buy resources! Sell your resources in bulk with us! Skip the refining process and sell to us at a premium!'
    },
    {
        title: 'Contracting',
        image: Covalex,
        body: "We accept contracts and help connect clients with interested subcontractors. We additionally offer insurance and guarantee on any of the work we do."
    },
    {
        title: 'Fleet',
        image: Fleet,
        body: "Manage your fleet and deliveries with us. We offer a dashboard to help you coordinate fulfillment of your contracts with your members."
    },
]